<template>
  <div class="d-flex flex-row flex-wrap flex-gap-md">
    <div class="flex-fill d-flex flex-column flex-gap-lg justify-content-center align-items-center">
      <div class="flex-fill d-flex flex-column justify-content-center align-items-center">
        <b-img
          :src="srcQRCode"
          style="height:18rem; width: auto;"
        />
      </div>

      <div class="flex-fill d-flex flex-column justify-content-top align-items-center">
        <h3 class="font-weight-bolder text-center">Scan the QR Code and input OTP to finish the setup</h3>
        <div class="mt-1">
          <TwoFactorAuthenticationInput
            v-model="otp"
          />
        </div>
      </div>
    </div>

    <div class="flex-fill">
      <b-tabs
        id="profile"
        v-model="activeTab"
        lazy
        pills
        class="tab-action-btn-fill-container"
        justified
      >
        <!-- Microsoft Authenticator -->
        <b-tab
          title="Microsoft Authenticator"
          icon-pack="feather"
          icon="icon-user"
        >
          <template
            #title
            size="18"
          >
            <div>
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <span>Microsoft Authenticator</span>
            </div>
          </template>
          <div class="tab-text">
            <h3>Steps to setup Microsoft Authenticator</h3>
            <div class="default-overflow">
              <ol class="m-0 p-0">
                <li>
                  Download Micsoroft Authenticator from AppStore / PlayStore. 

                  <div class="ml-2 my-1 d-flex flex-row flex-wrap flex-gap-md align-items-center">
                    <div>
                      <a
                        href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458?itsct=apps_box_badge&amp;itscg=30200"
                        style="display: inline-block; overflow: hidden; border-radius: 13px;"
                        target="_blank"
                      >
                        <img
                          width="auto"
                          height="60"
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1432944000"
                          alt="Download on the App Store"
                          style="border-radius: 13px;"
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=id&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                        target="_blank"
                      >
                        <img
                          width="auto"
                          height="88"
                          alt='Get it on Google Play'
                          src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                          style="margin-top: -7px; margin-left: -12px;"
                        >
                      </a>
                    </div>
                  </div>
                </li>
                <li>Login with your email. </li>
                <li>Press '+' icon on top-right to add a new OTP generator.</li>
                <li>Scan the barcode on this screen.</li>
                <li>Input the generated OTP to verify and complete the setup process.</li>
              </ol>
            </div>
          </div>
        </b-tab>

        <!-- Google Authenticator -->
        <b-tab
          title="Google Authenticator"
          icon-pack="feather"
          icon="icon-user"
        >
          <template
            #title
            size="18"
          >
            <div>
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <span>Microsoft Authenticator</span>
            </div>
          </template>
          <div class="tab-text">
            <div class="default-overflow">
              <ol class="m-0 p-0">
                <li>Download Micsoroft Authenticator from AppStore / PlayStore. </li>
                <li>Login with your email. </li>
                <li>Press '+' icon on top-right to add a new OTP generator.</li>
                <li>Scan the barcode on this screen.</li>
                <li>Input the generated OTP to verify and complete the setup process.</li>
              </ol>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div> 
</template>

<script>
import TwoFactorAuthenticationInput from './TwoFactorAuthenticationInput.vue'

export default {
  components: {
    TwoFactorAuthenticationInput,
  },

  props: {
    title: {
      type: String,
      default: 'View Document',
    },
  },

  data() {
    return {
      isLoading: true,
      activeTab: null,
      srcQRCode: null,
      otp: null,
    }
  },

  watch: {
    activeTab(newVal) {
      //
    },

    otp(newVal) {
      if (this.$isNotEmpty(newVal)) {
        if (newVal.length === 6) {
          this.verifyOTP()
        }
      }
    },
  },

  mounted() {
    this.generate2faQR()
  },

  methods: {
    generate2faQR() {
      this.$http
        .get('/api/user/2fa-qr')
        .then(response => {
          const { message, output, success } = response.data

          if (success) {
            this.srcQRCode = `data:image/png;base64,${output.qr_code}` 
          }
        })
        .catch(() => {})
    },

    verifyOTP() {
      this.$http
        .post('/api/user/2fa-verify-otp', {
          form_data: {
            otp: this.otp,
            setup: true,
          }
        })
        .then(response => {
          const { message, output, success } = response.data

          if (success) {
            this.$refreshUserInfo(this)

            this.$toastSuccess('2FA Operation', '2FA has been set up successfully')

            this.$emit('2fa-setup-success', true)
          } else {
            this.$toastDanger('2FA Operation', '2FA has setup failed, please try again.')
          }
        })
        .catch(() => {})
    },

    disable2faOTP() {
      this.$http
        .post('/api/user/2fa-disable-otp', {
          form_data: {
          }
        })
        .then(response => {
          const { message, output, success } = response.data

          if (success) {
            this.$refreshUserInfo(this)

            this.$toastSuccess('2FA Operation', '2FA has been disabled')
          }
        })
        .catch(() => {})
    },
  }
}
</script>

<style lang="scss" scoped>
.tab-text {
  margin-top: 2rem;
}
.app-store-badge {
  width: 2rem;
  height: auto;
}
</style>