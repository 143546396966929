/* eslint-disable import/prefer-default-export */

export const onResizeMixins = {
  data() {
    return {
      isMobile: false,
      isTablet: false,
    }
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      // window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },

  mounted() {
    this.onResize()
    // window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      this.isMobile = this.$isMobile()
      this.isTablet = this.$isTablet()
    }
  }
}