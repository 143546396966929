<template>
  <div ref="otpContainer" class="d-flex flex-row flex-wrap flex-gap-md justify-content-between">
    <input
      v-for="(digit, index) in otp"
      :ref="`otpInput${index}`"
      :key="index"
      v-model="otp[index]"
      class="otp-input"
      type="number"
      maxlength="1"
      @input="handleInput(index)"
      @click="handleClick(index)"
      @keydown="handleKeyDown($event, index)"
    >

    <div class="mt-2 d-flex w-100 justify-content-center align-items-center">
      <b-button
        variant="outline-danger"
        pill
        @click="clearOtp"
      >
        Clear
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'View Document',
    },
  },

  data() {
    return {
      otp: ['', '', '', '', '', ''], // Array to store OTP values
    }
  },

  computed: {
    mergedOTP() {
      return this.otp.join('');
    },
  },

  watch: {
    //
  },

  mounted() {
    setTimeout(() => {
      this.$refs[`otpInput${0}`][0].select()
    }, 5)
  },

  methods: {
    handleInput(index) {

      if (this.$isNotEmpty(parseInt(this.otp[index], 10))) {
        if (index < this.otp.length - 1) {
          this.$refs[`otpInput${index + 1}`][0].select()
        } else {
          this.$refs[`otpInput${index}`][0].blur()
        }
      }

      this.$emit('input', this.mergedOTP);
    },

    handleClick(index) {
      this.$refs[`otpInput${index}`][0].select();
    },

    handleKeyDown(event, index) {
      if (event.key === 'Backspace') {
        if (index > 0 && this.$isEmpty(this.otp[index])) {
          setTimeout(() => {
            this.$refs[`otpInput${index - 1}`][0].select()
          }, 5)
        } else {
          setTimeout(() => {
            this.$refs[`otpInput${index}`][0].select()
          }, 5)
        }
      }
    },

    clearOtp() {
      this.otp = ['', '', '', '', '', '']
      this.$refs[`otpInput${0}`][0].select()
    },
  }
}
</script>

<style lang="scss" scoped>
.otp-input {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin: 0 .3rem;
  border: .2rem solid grey;
}
</style>
